import React, { useRef, useState, useEffect, useCallback } from 'react';
import { gsap } from 'gsap';
import { Link } from 'react-router-dom';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useLocation } from 'react-router-dom';
import suntoryLogo from '../images/suntoryLogo.png';
import can_grapefruit from '../images/GRAPEFRUIT-PLAIN.png';
import can_lemon from '../images/LEMON-PLAIN.png';
import grapefruitFROST from '../images/GRAPEFRUIT-FROST.png';
import lemonFROST from '../images/LEMON-FROST.png';
import foreSmoke from '../images/CanForeground.png';
import s1 from '../images/Smoke1.png';
import s2 from '../images/Smoke2.png';
import whole from '../images/whole.png';
import freeze from '../images/freeze.png';
import crush from '../images/crush.png';
import coop from '../images/coop.png';
import whsmith from '../images/whsmith.png';
import amazon from '../images/amazon.png';
import tesco from '../images/tesco.png';
import yd from '../images/yd.png';
import nisa from '../images/nisa.png';
import bg from '../images/bg2.png';
// import bg3 from '../images/bg-flavour.jpg';
// import bg4 from '../images/bg-flavour-mob.jpg';
import yellowMist from '../video/196-web-bg.mp4';
import blackMist from '../video/smoke_bg_new.mp4';
import Mist from '../video/mist.mp4';
// import LazyVideo from '../components/LazyVideo';

// import usePreventScrollOnFocus from '../components/usePreventScrollOnFocus';

 import YouTubeVideo from '../components/YoutubeVideo';
 import thumbnail from '../images/thumbnail.png';
import useDeviceDetect from '../components/useDeviceDetect';
import drinkwareLogo from '../images/drinkaware.png';
import Logo196 from '../images/196-Logo.png';
import LogoFreezeCrush from '../images/196-FreezeCrush-Logo.png';
import LemonJp from '../images/lemon-jp.png';
import LemonJpMob from '../images/lemon-jp-black.png';
import LemonIcons from '../images/lemon-icons.png';
import GrapefruitJp from '../images/grapefruit-jp.png';
import GrapefruitJpMob from '../images/grapefruit-jp-black.png';
import GrapefruitIcons from '../images/grapefruit-icons.png';
import fb from '../images/facebook.png';
import insta from '../images/instagram.png';
import ytube from '../images/youtube.png';


import '../App.css';



const Home = () => {
    const tl = useRef(null);
    const dayRef = useRef(null);
    const monthRef = useRef(null);
    const yearRef = useRef(null);
    const lemonCan = useRef();
    const grapefruitCan = useRef();
    const lemonfrostCan = useRef();
    const grapefruitfrostCan = useRef();
    const lemonCan2 = useRef();
    const grapefruitCan2= useRef();
    const lemonfrostCan2 = useRef();
    const grapefruitfrostCan2 = useRef();
    const flavourRef = useRef(null);
    const smok1 = useRef();
    const smok2 = useRef();
    const smok3 = useRef();
    const smok4 = useRef();
    const smok5 = useRef();
    const smok6 = useRef();
    const canforegroundOpacity = useRef();
    const canforegroundOpacity2 = useRef();
    
    const canVideoRef = useRef(null);
    const ageVideoRef = useRef(null);
    const aboutVideoRef = useRef(null);
    const wbVideoRef = useRef(null);
    const imagesRef = useRef([]);
    const textRef = useRef(null);
    const [isHeaderVisible, setIsHeaderVisible] = useState(true);
    const [isContentVisible, setIsContentVisible] = useState(false);

    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [error, setError] = useState('');
    const [young, setYoung] = useState('');

    // const inputRefs = useRef([dayRef.current, monthRef.current, yearRef.current]);
    // usePreventScrollOnFocus(inputRefs);





    
    //eslint-disable-next-line
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrollTriggerLoaded, setIsScrollTriggerLoaded] = useState(false);
    const [isAndroid, isChrome] = useDeviceDetect();

    // const reloadPage = (newUrl) => {
    //     window.location.href = newUrl;
    // };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

     // Function to check URL and scroll to section if it matches

     const extractIdFromUrl = useCallback(() => {
        const url = window.location.href;
        // Define the ending pattern for the URL
        const pattern = /\/([^/]+)\/?$/; // Remove unnecessary escape character
        // Match the pattern against the URL
        const match = url.match(pattern);
        if (match && match[1]) {
            // Extracted ID is the captured group from the regex
            const extractedId = match[1].replace('#', ''); // Remove '#' character
            return extractedId;
        }
        return null; // Return null if no match found
    }, []);

     const checkUrlAndScroll = useCallback(() => {
        const id = extractIdFromUrl();
        console.log(id);
        if (id) {
            if (id === 'flavours'){
                scrollNormal(id);
            } else{
                scrollToSection(id); // Call scrollToSection with the extracted ID
            }
           
            
        }
    }, [extractIdFromUrl]); // No dependencies, so it will always refer to the latest version of extractIdFromUrl

    useEffect(() => {
        checkUrlAndScroll(); // Call checkUrlAndScroll on page load
    }, [checkUrlAndScroll]); // Include checkUrlAndScroll in the dependency array of useEffect

    const handleButtonClick = useCallback(() => {
        const currentDate = new Date();
        const [dayNum, monthNum, yearNum] = [day, month, year].map(Number); // Convert strings to numbers

        if (!dayNum || !monthNum || !yearNum || isNaN(dayNum) || isNaN(monthNum) || isNaN(yearNum)) {
            setError('Please make sure all fields are input correctly.');
            return;
        }

        const birthDate = new Date(yearNum, monthNum - 1, dayNum); // month - 1 because months are zero-indexed in JavaScript
        const isValidDate = birthDate.getDate() === dayNum && birthDate.getMonth() === monthNum - 1 && birthDate.getFullYear() === yearNum;

        if (!isValidDate) {
            setError('Please enter a valid date.');
            return;
        }

        if (birthDate > currentDate) {
            setError('Please enter a valid date.');
            setYoung('');
            return;
        }

        let age = currentDate.getFullYear() - birthDate.getFullYear();
        const m = currentDate.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && currentDate.getDate() < birthDate.getDate())) {
            age--;
        }

        if (age >= 18) {
            setError('');
            setYoung('');
            setIsContentVisible(true);
            setTimeout(() => {
                setIsHeaderVisible(false);
            }, 1500);
            const remainingTime = 1500;
            const delayAfterFunctionCompletes = remainingTime + 500;
            setTimeout(() => {
                animateCans();
                animateSmoke(smok1, 0);
                animateSmoke(smok2, 1);
                animateSmoke(smok3, 2);
                animateSmoke(smok4, 0);
                animateSmoke(smok5, 1);
                animateSmoke(smok6, 2);
                checkUrlAndScroll();
            }, delayAfterFunctionCompletes);
        } else {
            setYoung('Unfortunately you are not old enough yet.');
            setError('');
        }

    }, [day, month, year, checkUrlAndScroll]);

    const handleInputChange = (setter, value, maxLength, nextRef) => {
        if (/^\d*$/.test(value) || value === '') {
            setter(value);
            if (value.length === maxLength && nextRef) {
                nextRef.current.focus();
            }
        }
    };

    const getInvalidMessage = (value) => {
        if (value === '') {
            return 'Please fill in this field.';
        } else if (!/^\d*$/.test(value)) {
            return 'Please match the format requested.';
        }
        return '';
    };

    const animateCans = () => {
        tl.current = gsap.timeline({ repeat: 0, repeatDelay: 0 });
        tl.current.to([grapefruitCan.current], { top: 0, duration: 1.5, }, "+=0");
        tl.current.to([lemonCan.current], { top: 0, duration: 1.5, }, "-=1");
        tl.current.to([lemonfrostCan.current, grapefruitfrostCan.current, canforegroundOpacity.current], { opacity: 1, duration: 2, }, "-=1");
    };

    const animateCans2 = () => {
        tl.current = gsap.timeline({ repeat: 0, repeatDelay: 0 });
        tl.current.to([grapefruitCan2.current], { top: 0, duration: 1.5, }, "+=0");
        tl.current.to([lemonCan2.current], { top: 0, duration: 1.5, }, "-=1");
        tl.current.to([lemonfrostCan2.current, grapefruitfrostCan2.current,canforegroundOpacity2.current], { opacity: 1, duration: 2, }, "-=1");
    };

    const animateSmoke = (ref, delay) => {
        const tl = gsap.timeline({ repeat: -1, repeatDelay: 0 });
        tl.to(ref.current, { scale: 1.4, duration: 4, }, delay);
        tl.to(ref.current, { opacity: 1, duration: 2, }, "-=4");
        tl.to(ref.current, { opacity: 0, duration: 2, }, "-=2");
        tl.to(ref.current, { scale: 1, duration: 0.2, }, "-=0");
    };

    useEffect(() => {
        if (canVideoRef.current) {
            canVideoRef.current.playbackRate = 0.5; // Adjust can playback rate as needed (0.5 is half speed)
        }
        if (ageVideoRef.current) {
            ageVideoRef.current.playbackRate = 1; // Adjust age playback rate as needed (0.5 is half speed)
        }

        if (aboutVideoRef.current) {
            aboutVideoRef.current.playbackRate = 1; // Adjust about us playback rate as needed (0.5 is half speed)
        }
        if (wbVideoRef.current) {
            wbVideoRef.current.playbackRate = 1; // Adjust where to buy playback rate as needed (0.5 is half speed)
        }
    }, []);


    useEffect(() => {
        if (isContentVisible) {
            setTimeout(() => {
                gsap.registerPlugin(ScrollTrigger);
                setIsScrollTriggerLoaded(true);
                // Call the function when the page loads

            }, 1500); // Adjust the delay as needed
        }
    }, [isContentVisible]);


    useEffect(() => {
        if (isContentVisible) {
            gsap.registerPlugin(ScrollTrigger);

            imagesRef.current.forEach((image, index) => {
                gsap.fromTo(
                    image, {
                        scale: 0,
                        opacity: 0,
                        ease: 'bounce.out',
                    }, {
                        scrollTrigger: {
                            trigger: image,
                            start: 'top 75%',
                            end: 'bottom 75%',
                            // markers: true,
                            onEnter: () => {
                                gsap.to(image, {
                                    scale: 1,
                                    opacity: 1,
                                    duration: 0.25,
                                    delay: index * 0.5,
                                    ease: 'elastic.out(1, 0.5)'
                                });

                                // Animation for the text
                                if (index === imagesRef.current.length - 1) { // Check if it's the last image
                                    gsap.fromTo(
                                        textRef.current, {
                                            opacity: 0,
                                        }, {
                                            opacity: 1,
                                            duration: 0.25,
                                            delay: (index + 1) * 0.5, // Start text animation after all images
                                        }
                                    );
                                }
                            },
                            onLeaveBack: () => {
                                gsap.to(image, {
                                    scale: 0,
                                    opacity: 0,
                                    duration: 0.25,
                                    ease: 'bounce.out'
                                });

                                // On leaving back, hide the text
                                gsap.to(textRef.current, {
                                    opacity: 0,
                                    duration: 0.25,
                                });
                            }
                        }
                    }
                );
            });

        }
    }, [isScrollTriggerLoaded, isContentVisible]);


  // This effect sets up ScrollTrigger when it's loaded and content is visible
  useEffect(() => {
    if (isContentVisible) {
      ScrollTrigger.create({
        trigger: flavourRef.current,
        start: 'top 85%',
        end: 'bottom 85%',
        onEnter: () => animateCans2(),
        // markers: true // Enable markers
      });

      // Clean up the ScrollTrigger on component unmount
      return () => {
        ScrollTrigger.getAll().forEach(trigger => trigger.kill());
      };
    }
  }, [isContentVisible]);

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        console.log(id);
        if (section) {
            const windowHeight = window.innerHeight; // Height of the browser window
            let finalScrollPosition = section.offsetTop - (windowHeight * 0.25); // Adjusted scroll position

            // Adjust scroll position based on screen width
            const windowWidth = window.innerWidth;
            if (windowWidth > 1100) {
                finalScrollPosition += 150;
            } else if (windowWidth > 768) {
                finalScrollPosition += 80;
            } else if (windowWidth > 600) {
                finalScrollPosition += 130;
            } else {
                finalScrollPosition += 60;
            }

            // Ensure scrollPosition is not negative
            finalScrollPosition = finalScrollPosition >= 0 ? finalScrollPosition : 0;
            console.log(finalScrollPosition);
            window.scrollTo({

                top: finalScrollPosition,
                behavior: 'smooth'
            });
            setIsMenuOpen(false); // Close the navigation overlay after scrolling
        }
    };

    const scrollNormal = (id) => {
        const section = document.getElementById(id);
        console.log(id);
    
        if (section) {
            const finalScrollPosition = section.offsetTop;
            console.log(finalScrollPosition);
    
            window.scrollTo({
                top: finalScrollPosition,
                behavior: 'smooth'
            });
    
            // Close the navigation overlay after scrolling
            setIsMenuOpen(false);
        }
    };

    return (
        <div className="App  h-full">

        {/* AGE VERIFICATION -----------------------------------------------------------------*/}

        {isHeaderVisible && (
        <div id='age-gate' className="  h-full w-full fixed z-50 flex justify-center items-center flex-col ">
            <video playsInline ref={ageVideoRef} autoPlay loop muted className="  w-full h-full object-cover fixed" >
                <source src={Mist} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className=" z-10  rounded-lg md:w-[716px] md:h-[460px] xs:w-[90%] sm:h-[483px] mob2:h-[450px] xs:h-[440px] bg-[#fedd00] md:mx-[15px] sm:mx-[38px] mob2:mx-[28px] xs:mx-[18px] flex flex-col items-center">
                <img src={suntoryLogo} className="md:mt-[50px] xs:mt-[50px] mx-auto md:w-[240px] mob2:w-[240px] xs:w-[200px]" alt="logo" />
                <p className="md:mt-[60px] xs:mt-[60px] font-barlowBold font-extrabold  mx-auto md:text-[33px]  sms:text-[32px] sm:text-[30px] mob2:text-[24px] xs:text-[20px] sm:leading-8 xs:leading-6 tracking-tight md:w-[447px] sm:w-[380px] mob2:w-[280px] mob:w-[240px] xs:w-[240px]">
                    TO VISIT MINUS196.CO.UK YOU MUST BE OVER THE AGE OF 18
                </p>
                {!young && (
                    <div className="flex justify-center space-x-4 mt-10">
                    <input
                        type="text" placeholder="DD"  ref={dayRef} style={{ touchAction: 'manipulation' }} value={day} onChange={(e) => handleInputChange(setDay, e.target.value, 2, monthRef)} maxLength={2}  title={getInvalidMessage(day)} className=" font-barlowBold text-left pl-5 sm:w-[130px] mob2:w-[100px]  xs:w-[80px] h-[45px] xs:rounded-[8px] outline-none "/>
                    <input
                        type="text" placeholder="MM"   ref={monthRef} style={{ touchAction: 'manipulation' }}  value={month}  onChange={(e) => handleInputChange(setMonth, e.target.value, 2, yearRef)} maxLength={2}  title={getInvalidMessage(month)} className="font-barlowBold text-left pl-5 sm:w-[130px] mob2:w-[100px]  xs:w-[80px]  h-[45px] xs:rounded-[8px]  outline-none"/>
                    <input
                        type="text" placeholder="YYYY"   ref={yearRef}  style={{ touchAction: 'manipulation' }} value={year} onChange={(e) => handleInputChange(setYear, e.target.value, 4, )}  maxLength={4}  title={getInvalidMessage(year)} className="font-barlowBold text-left pl-5  sm:w-[130px] mob2:w-[100px]  xs:w-[80px] h-[45px] active: xs:rounded-[8px]  outline-none "/>
                    </div>
                )}

                    {young && <p className="text-red-500 mt-10 mob2:w-[90%] xs:w-[70%] font-barlowBold font-extrabold " >{young}</p>}
                    {error && <p className="text-red-500 mt-2 font-barlowBold font-extrabold xs:w-[230px] mob2:w-[100%] ">{error}</p>}
                    
                    <button onClick={handleButtonClick} className={`flex items-center justify-center  mx-auto md:rounded-[8px] xs:rounded-[8px] md:w-[350px] md:h-[54px] sm:w-[350px] mob2:w-[250px] xs:w-[200px] xs:h-[54px] bg-[#1D1D1B] text-[#fedd00] md:mb-[50px] xs:mb-[0px] ${error ? 'md:mt-[30px] xs:mt-[20px]' : 'md:mt-[50px] xs:mt-[50px]'}`}>
                        <p className="text-center md:text-[22px]  sm:text-[22px] mob2:text-[16px] xs:text-[14px] font-barlowBold font-extrabold tracking-tight">
                            I AM OVER THE AGE OF 18
                        </p>
                    </button>
            </div>
            <div id='disclaimer' style={{ display: isHeaderVisible  ? 'block' : 'none' }}  className='   relative mx-auto w-full  md:pt-[100px] sms:pt-[10%] xs:pt-[10%]  '>
                <p className="relative md:mt-[0px] sms:mt-[0px] xs:mt-[0px] font-barlowBold font-light x904:text-[16px] sms:text-[16px] sm:text-[15px] mob2:text-[14px]  xs:text-[13px] leading-tight text-[#ffffff] xs:mx-auto min-[1220px]:w-[536px] x904:w-[536px] sms:w-[536px] sm:w-[500px] mob2:w-[365px] xs:w-[300px]">
                    By entering the site you accept our privacy and cookie policy. Please enjoy responsibly.
                    Do not share or forward this website or its content to anyone under 18.<br/><br/>
                </p>
                <div className="flex items-center x902:mx-0 xs:mx-auto min-[1220px]:w-[192px] x904:w-[192px] sms:w-[192px] sm:w-[192px] mob2:w-[192px] xs:w-[192px] ">
                    <span className="relative pt-[6px] font-barlowBold font-normal x904:text-[20px] sms:text-[20px] sm:text-[20px] mob2:text-[20px] xs:text-[20px] leading-none text-[#ffffff]">
                        <b>18+</b>&nbsp;
                    </span>
                    <img src={drinkwareLogo} className="mt-2 relative min-[1220px]:w-[164px] x904:w-[164px] sms:w-[164px] sm:w-[164px] mob2:w-[164px] xs:w-[160px]" alt="drinkware-Logo" />
                </div>
             </div>
            
        </div>
        )}
        {/* AGE VERIFICATION END--------------------------------------------------------------*/}

        {/* /////////////////////////////////HOME PAGE CONTENT/////////////////////////////////*/}

        {/* HEADER SECTION */}
        <div  style={{  display: isContentVisible ? 'block' : 'none', position: isContentVisible ? 'relative' : 'fixed' }}  className=' header bg-[#282728]  xs:h-[1990px] mob2:h-[2575px]   sm:h-[3000px] sms:h-[3892px]  md:h-[3932px] x904:h-[1957px]   lg:h-[2011px] lgm:h-[2051px]  xl1130:h-[2109px]   x1220:h-[2284px] xl:h-[2284px] desk:h-[2395px]'> 
             {/* Navigation bar */}
            
            <div className={` mx-auto max-w-[1440px] x904:h-[128px] absolute w-full z-[11] left-1/2 translate-x-[-50%]  flex items-center   justify-between `}>
                {/* Reload page */}
                <Link to="/" className='cursor-pointer'>
                    <img src={Logo196} className=" cursor-pointer z-20 x904:mt-6 sms:mt-6 sm:mt-6 mob2:mt-6 xs:mt-4 x904:ml-12 xs:ml-[5%]  x904:w-[125px]  sms:w-[180px] sm:w-[145px] mob2:w-[100px]  xs:w-[80px]  " alt="logo" />
                </Link>
                {/* Burger SVG (conditionally rendered based on screen width) */}
                <svg onClick={toggleMenu} className=" z-20 x904:hidden ml-4 sms:h-16 sms:w-16 sm:w-14 sm:h-14 mob2:w-10 mob2:h-10 xs:w-8 xs:h-8 mr-[5%]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path  className={`transition-transform  ${isMenuOpen ? ' -rotate-45 translate-y-2 -translate-x-[0.6rem]' : ''}`} d="M4 18L20 18" stroke="#ffffff" strokeWidth="1" strokeLinecap="round"/>
                    <path className={`transition-transform ${isMenuOpen ? 'opacity-0 ' : ''}`}  d="M4 12L20 12" stroke="#ffffff" strokeWidth="1" strokeLinecap="round"/>
                    <path className={`transition-transform ${isMenuOpen ? 'rotate-45  translate-x-[0.45rem] -translate-y-[0.02rem]' : ''}`} d="M4 6L20 6" stroke="#ffffff" strokeWidth="1" strokeLinecap="round"/>
                </svg>
                {/* DESKTOP NAV OPTIONS */}
                <div className=' flex-row space-x-8 text-[#fedd00] font-barlowBold font-extrabold text-[22px] mr-10 x904:flex xs:hidden'>
                    <p  onClick={() => scrollToSection('about-us')} className=' cursor-pointer'>ABOUT US</p>
                    <p  onClick={() => scrollNormal('flavours')} className=' cursor-pointer'>FLAVOURS</p>
                    <p  onClick={() => scrollToSection('where-to-buy')} className=' cursor-pointer'>WHERE TO BUY</p>
                    <Link to='/Contact' className='cursor-pointer'> <p   className=' cursor-pointer'>CONTACT US</p></Link>
                </div>

                {/* Overlay Mobile */}
                <div className={`fixed top-0 left-0 w-full h-[100vh] bg-black  z-10 xs:flex justify-center items-center x904:hidden transition-transform duration-[400ms] ease-in  ${isMenuOpen ? 'translate-y-0' : '-translate-y-full'} `}>
                    {/* Navigation links */}
                    <div className="flex flex-col space-y-4 text-white font-barlowBold font-extrabold text-2xl">
                        <p  onClick={() => scrollToSection('about-us')}className='cursor-pointer'>ABOUT US</p>
                        <p  onClick={() => scrollNormal('flavours')} className=' cursor-pointer'>FLAVOURS</p>
                        <p  onClick={() => scrollToSection('where-to-buy')} className='cursor-pointer'>WHERE TO BUY</p>
                        <Link to='/Contact' className='cursor-pointer'> <p   className=' cursor-pointer'>CONTACT US</p></Link>
                    </div>
                </div>
            </div>
             
            {/* Navigation bar closed */}
           
            <div className='bg-[#fedd00] h-full  '>
                {/* !!VIDEO FOR THE ABOUT US SECTION NEEDS TO BE PLACED HERE TO WORK!!! */}
                <video  playsInline ref={aboutVideoRef} autoPlay loop muted className="w-full h-full object-cover fixed"> 
                    <source src={yellowMist} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

                {/* TOP CANS SECTION------------------------------------------------------------------ */}
                <div className=' flex justify-center relative overflow-hidden z-[10] bg-[#1D1D1B]  qk3800:h-[1650px]   qk3200:h-[1450px] qk1550:h-[1350px]  desk:h-[1090px] desk2:h-[1090px]  x904:h-[995px] sms:h-[1473px] sm:h-[1244px] xs:h-[1100px]  xs:cp-s-1 mob2:cp-size-1   sm:cp-550-1  min-[600px]:cp-600-1  min-[700px]:cp-700-1  x904:cp-904-1  min-[1100px]:cp-1100-1 min-[1300px]:cp-1300-1  min-[1400px]:cp-1400-1 min-[1700px]:cp-1700-1  min-[2100px]:cp-2100-1   min-[2740px]:cp-2900-1  min-[3230px]:cp-4k-plus-1' >
                    <video playsInline ref={canVideoRef} autoPlay loop muted className="  w-full h-full object-cover absolute  xs:h-[820px] mob2:h-[960px]  sm:h-[1260px]  min-[600px]:h-[1440px]  min-[700px]:h-[1440px]  x904:h-[860px]  min-[1100px]:h-[960px] min-[1300px]:h-[1000px]  min-[1400px]:h-[1100px] min-[1700px]:h-[1230px] min-[2100px]:h-[1300px]   min-[2740px]:h-[1360px]  min-[3230px]:h-[1430px]">
                        <source src={blackMist} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className='mx-auto max-w-[1440px] absolute w-full  h-full'>
                    <img src={foreSmoke}  ref={canforegroundOpacity}  style={{ opacity:0 }} className={`z-30 min-[904px]:w-[977px] tablet:w-[900px] md:w-[800px] md2:w-[800px] sms:w-[1160px] sm:w-[766px] mob2:w-[550px] xs:w-[450px]  min-[1300px]:top-[612px] min-[1100px]:top-[497px] x904:top-[434px] tablet:top-[1010px] md:top-[1010px] md2:top-[1036px] sms:top-[1039px] sm:top-[907px] mob2:top-[648px] xs:top-[556px] absolute  `} alt="can gradient" />
                    </div>
                    <div className='  sms:mt-[0px] x904:mt-[150px] xs:mt-[50px] z-20 mx-auto  max-w-[1440px] flex x904:flex-row  xs:flex-col  x904:justify-center' >
                        {/* CANS-------------------------------- */}
                        <div className='z-20  min-[1300px]:mr-[8%] x904:mr-[4%]  x904:mt-0 sms:mt-0 sm:mt-0  x904:order-1  xs:order-2 sms:mx-0 xs:mx-[5%]'>
                        
                            <div id='backgroundlayer'  style={{ backgroundImage:`url(${bg})`, backgroundSize:'cover', backgroundPosition: 'center' }} className="z-20 absolute inset-0 "></div>
                            <div id='canForeground'  style={{ backgroundImage:`url(${foreSmoke})`, backgroundSize:'cover', backgroundPosition: 'center' }} className={`z-[17] absolute inset-0 min3240:top-[10%] min2740:top-[23%]  min-[2100px]:top-[20%]   min-[1700px]:top-[20%] min-[1550px]:top-[25%] min-[1500px]:top-[44%]  min-[1300px]:top-[46%] min-[1550px]:left-[0%] min-[1300px]:left-[-25%]  min-[1100px]:left-[-14%] min-[1100px]:top-[40%] x904:top-[25%]  sms:top-[57%]  sm:top-[65%]   mob2:top-[40%]  xs:top-[25%] `}></div>
                            <>
                                {isAndroid && isChrome ? (
                                    <>
                                    {/* Render elements for Chrome on Android */}                 
                                    <div id='smoke1' style={{ opacity:0, backgroundImage:`url(${s1})`, backgroundSize:'cover', backgroundPosition: 'center' }}  className=" smokeAnimation animation1 z-[18] absolute inset-0  min3240:left-[-350%] min2740:left-[-10%]  min-[2100px]:left-[-10%]   min-[1700px]:left-[-10%] min-[1500px]:left-[-10%]  min-[1300px]:left-[-10%]  min-[1100px]:left-[-15%] x904:left-[-10%]  xs:left-[0px]  "></div>
                                    <div id='smoke2'   style={{ opacity:0, backgroundImage:`url(${s2})`, backgroundSize:'cover', backgroundPosition: 'center'  }}  className="smokeAnimation animation2 z-[19] absolute inset-0  min3240:left-[-320%] min2740:left-[-12%]  min-[2100px]:left-[-12%]   min-[1700px]:left-[-12%] min-[1500px]:left-[-12%]  min-[1300px]:left-[-12%]  min-[1100px]:left-[-12%] x904:left-[-12%]  xs:left-[0px]"></div>
                                    <div id='smoke3'   style={{ opacity:0, backgroundImage:`url(${s1})`, backgroundSize:'cover', backgroundPosition: 'center'  }} className=" smokeAnimation animation3 z-[18] absolute inset-0  min3240:left-[-350%] min2740:left-[-10%]  min-[2100px]:left-[-10%]   min-[1700px]:left-[-10%] min-[1500px]:left-[-10%]  min-[1300px]:left-[-10%]  min-[1100px]:left-[-15%] x904:left-[-10%]  xss:left-[0px] "></div>
                                    </>
                                ) : (
                                    <>
                                    {/* Render elements for other devices/browsers */}
                                    <div id='smoke1' ref={smok1} style={{ opacity:0, backgroundImage:`url(${s1})`, backgroundSize:'cover', backgroundPosition: 'center' }}  className=" z-[18] absolute inset-0  min3240:left-[-350%] min2740:left-[-10%]  min-[2100px]:left-[-10%]   min-[1700px]:left-[-10%] min-[1500px]:left-[-10%]  min-[1300px]:left-[-10%]  min-[1100px]:left-[-15%] x904:left-[-10%]  xs:left-[0px]  "></div>
                                    <div id='smoke2' ref={smok2}   style={{ opacity:0, backgroundImage:`url(${s2})`, backgroundSize:'cover', backgroundPosition: 'center'  }}  className=" z-[19] absolute inset-0  min3240:left-[-320%] min2740:left-[-12%]  min-[2100px]:left-[-12%]   min-[1700px]:left-[-12%] min-[1500px]:left-[-12%]  min-[1300px]:left-[-12%]  min-[1100px]:left-[-12%] x904:left-[-12%]  xs:left-[0px]"></div>
                                    <div id='smoke3' ref={smok3}  style={{ opacity:0, backgroundImage:`url(${s1})`, backgroundSize:'cover', backgroundPosition: 'center'  }} className="  z-[18] absolute inset-0  min3240:left-[-350%] min2740:left-[-10%]  min-[2100px]:left-[-10%]   min-[1700px]:left-[-10%] min-[1500px]:left-[-10%]  min-[1300px]:left-[-10%]  min-[1100px]:left-[-15%] x904:left-[-10%]  xss:left-[0px] "></div>
                                    </>
                                )}
                            </>
                            
                            <div className={`relative  xs:mx-auto w-[600px] min-[1300px]:w-[600px] min-[1100px]:w-[500px] x904:w-[450px] sms:w-[600px] sm:w-[550px] mob2:w-[410px] xs:w-[350px] animated-div `}>
                                <div className=" mx-auto md:mt-15 xs:mt-0 xs:mx-auto  min-[1300px]:max-w-[600px] min-[1100px]:max-w-[500px] x904:max-w-[450px] sms:max-w-[600px] relative">
                                    {/* GRAPEFRUIT */}
                                    <div id='grapefruit'  ref={grapefruitCan} style={{ top:'45vh' }}  className={`absolute min-[1300px]:w-[325px]  min-[1100px]:w-[260px]  x904:w-[229px]  min-[1100px]:mt-[51px] min-[1300px]:mt-[65px] x904:mt-[48px] sms:w-[312px] sms:mt-[58px] sm:w-[260px] sm:mt-[51px]  mob2:w-[195px] mob2:mt-[39px] xs:w-[157px] xs:mt-[47px]  `}>
                                        <img src={can_grapefruit} className={`absolute z-20   `} alt="grapefruit can" />
                                        <img ref={grapefruitfrostCan} src={grapefruitFROST}  className={` absolute z-20 `} style={{ opacity:0 }} alt="frost grapefruit can" />
                                    </div> 
                                    {/* LEMON */}
                                    <div id='lemon' ref={lemonCan}  style={{ top:'45vh' }} className={`absolute min-[1300px]:w-[363px] min-[1300px]:left-[252px] min-[1100px]:w-[290px] min-[1100px]:left-[203px] x904:w-[255px] x904:left-[179px] sms:w-[345px] sms:left-[244px] sm:w-[290px] sm:left-[203px] mob2:w-[217px] mob2:left-[154px] xs:w-[184px] xs:left-[123px] `}>
                                        <img src={can_lemon} className={` absolute z-20`} alt="lemon can" />
                                        <img  ref={lemonfrostCan} src={lemonFROST} className={` absolute z-20 `} style={{ opacity:0 }} alt="frost lemon can" />
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* CANS END----------------------------- */}
                        {/* COPY--------------------------------- */}
                        <div className='z-[23] mx-auto  min-[1300px]:h-[548px]  min-[1100px]:h-[477px]  x904:h-[446px]  sms:h-[548px] sm:h-[450px]  mob2:h-[300px] xs:h-[250px] flex justify-center items-center x904:order-2 xs:order-1'>
                        {/* <div className='z-[23] mx-auto  sms:h-[548px] sm:h-[450px]  mob2:h-[300px] xs:h-[250px] flex justify-center items-center x904:order-2 xs:order-1'> */}
                            <div className="relative xs:mx-auto   max-w-[600px] text-[#fedd00] text-center font-barlowBold font-extrabold sm:h-[215px] mob2:h-[190px] xs:h-[200px] ">
                                <p className='mx-auto min-[1300px]:text-[39px] min-[1100px]:text-[35px] x904:text-[28px] md:text-[39px] sms:text-[39px] sm:text-[35px] mob2:text-[28px] xs:text-[24px] tracking-[-0.010em]'>
                                THERE'S
                                </p>
                                <p className='mx-auto min-[1300px]:text-[136px] min-[1100px]:text-[122px] x904:text-[96px] md:text-[136px] sms:text-[136px] sm:text-[122px] mob2:text-[96px] xs:text-[70px] tracking-[-0.04em] leading-[0.5em]'>
                                METHOD
                                </p>
                                <p className='mx-auto min-[1300px]:text-[39px] min-[1100px]:text-[35px] x904:text-[28px] md:text-[39px]  sms:text-[39px] sm:text-[35px] mob2:text-[28px] xs:text-[24px] tracking-[-0.010em]  leading-[2em]'>
                                IN THE
                                </p>
                                <p className='mx-auto min-[1300px]:text-[136px] min-[1100px]:text-[122px] x904:text-[96px] md:text-[136px]  sms:text-[136px] sm:text-[122px] mob2:text-[96px] xs:text-[70px] tracking-[-0.04em] leading-[0.35em]'>
                                MINUS
                                </p>
                            </div>
                        </div>
                        {/* COPY END------------------------------- */}
                    </div>
                </div>
                {/* TOP CAN SECTION END---------------------------------------------------------------- */}

                {/* ABOUT US SECTION------------------------------------------------------------------- */}
                <div id='about-us' style={{ display: isContentVisible ? 'block' : 'none' }}  className=' z-10 absolute left-1/2 translate-x-[-50%]  mx-auto min-[1440px]:w-[1440px] xl:w-[1340px] min-[1220px]:w-[1200px] min-[1130px]:w-[1100px] min-[1050px]:w-[1000px] min-[920px]:w-[900px] min-[870px]:w-[850px] min-[820px]:w-[800px]  sms:w-[600px] sm:w-[495px]  mob2:w-[395px] xs:w-[300px] desk:top-[870px] xl:top-[800px]  min-[920px]:top-[800px] min-[1050px]:top-[800px]  xl1130:top-[800px]  min-[1220px]:top-[800px]  x904:top-[800px]  sms:top-[1300px] sm:top-[1100px] mob2:top-[830px]   xs:top-[700px]  '>
                    {/* YOUTUBE VIDEO  */}
                    <div className=' max-w-[1272px] max-h-[715px] w-auto min-[1440px]:h-[715px] xl:h-[675px] min-[1220px]:h-[675px] min-[1130px]:h-[618px] min-[1050px]:h-[562px] min-[920px]:h-[506px] min-[870px]:h-[478px] min-[820px]:h-[450px]  sms:h-[338px] sm:h-[280px]  mob2:h-[224px] xs:h-[170px] mx-auto bg-black 
                        min-[1440px]:w-[1272px] xl:w-[1200px] min-[1220px]:w-[1200px] min-[1130px]:w-[1100px] min-[1050px]:w-[1000px] min-[920px]:w-[900px] min-[870px]:w-[850px] min-[820px]:w-[800px]  sms:w-[600px] sm:w-[495px]  mob2:w-[395px] xs:w-[300px]'>
                        <YouTubeVideo 
                            videoId="zH5CGfEWVSI"
                            thumbnail={thumbnail}
                        />
                    </div>
                    {/* YOUTUBE VIDEO END */}

                    <p    className="relative min-[1100px]:mt-[100px] md:mt-[108px] sms:mt-[108px] xs:mt-[54px] font-barlowBold font-extrabold x904:text-[32px] sms:text-[52px] sm:text-[42px] mob2:text-[32px]  xs:text-[28px] leading-tight">
                        HOW WE MAKE -196
                    </p>
                    <div  className="  x902:mt-[35px] xs:mt-[0px] flex  x904:flex-row  xs:flex-col *:justify-between  min-[1220px]:w-[1076px] x904:w-[840px] sms:w-[550px] sm:w-[400px]  mob2:w-[350px]  xs:w-[250px] mx-auto">
                        <img  style={{ opacity:0 }} ref={(el) => (imagesRef.current[0] = el)} src={whole} className="x902:mx-0 xs:mx-auto relative min-[1220px]:w-[370px] x904:w-[240px] sms:w-[600px] sm:w-[400px]  mob2:w-[350px]   xs:w-[250px]" alt="whole lemon" />
                        <img  style={{ opacity:0 }} ref={(el) => (imagesRef.current[1] = el)} src={freeze} className=" x902:mx-0 xs:mx-auto relative  min-[1220px]:w-[370px]  x904:w-[240px] sms:w-[600px] sm:w-[400px]  mob2:w-[350px] xs:w-[250px]" alt="freeze lemon" />
                        <img   style={{ opacity:0 }} ref={(el) => (imagesRef.current[2] = el)}  src={crush} className=" x902:mx-0 xs:mx-auto relative min-[1220px]:w-[370px] x904:w-[240px]  sms:w-[600px] sm:w-[400px]  mob2:w-[350px] xs:w-[250px]" alt="crush lemon" />
                    </div>
                        <div  style={{ opacity:0 }}  ref={textRef}  className="  min-[1100px]:mb-[100px] md:mb-[80px] sms:mb-[130px] xs:mb-[20px] mx-auto  animation-text  x904:w-[656px] sms:w-[518px] sm:w-[389px] mob2:w-[333px] xs:w-[239px] relative md:mt-[40px] xs:mt-[40px] x904:text-[20px] sms:text-[28px] sm:text-[21px] mob2:text-[18px]  xs:text-[12.9px]   x904:leading-[1.2rem] sms:leading-[1.7rem] sm:leading-[1.4rem] mob2:leading-[1.2rem] xs:leading-[1rem] font-barlowBold font-extrabold whitespace-pre-wrap">
                        <p >
                        BIG IN JAPAN, NOW AVAILABLE IN THE UK.
                        <br/><br/>
                        FROM SUNTORY’S NO. 1 PREMIX IN JAPAN, WE’VE BROUGHT THE UNIQUE FREEZE CRUSH TECHNOLOGY™ TO THE UK MARKET*.
                        <br/><br/>
                        MINUS 196 IS MADE BY FREEZING A WHOLE FRUIT WITH LIQUID NITROGEN AT -196ºC THEN CRUSHING AND INFUSING WITH SHOCHU, VODKA AND SODA TO CAPTURE AND MAINTAIN EVERY BIT OF ALL NATURAL BOLD FRUIT FLAVOUR. 
                        <br/><br/>
                        *MADE IN THE EU.
                        </p>
                        </div>
                </div>
                {/* ABOUT US SECTION END------------------------------------------------------------------- */}
            </div>

        </div>
        {/* HEADER SECTION END */}

        {/* FLAVOUR SECTION */}
        <div  id="flavours"  style={{ display: isContentVisible ? 'block' : 'none', position: isContentVisible ? 'relative' : 'fixed' }}  className='  x904:bg-bg-desktop xs:bg-bg-mobile bg-cover bg-center flex justify-center overflow-hidden z-[10] bg-[#1D1D1B] min-[1300px]:h-[814px]  x904:h-[685px] md2:h-[1715px] sms:h-[1585px] sm:h-[1300px] mob2:h-[980px] xs:h-[850px]' >
            {/* LOGO */}
            <div className=' mx-auto max-w-[1440px]  absolute w-full z-[11] left-1/2 translate-x-[-50%] flex items-center justify-between'>
                <img src={LogoFreezeCrush} className=" cursor-pointer z-20 x904:mt-20 sms:mt-16 sm:mt-16 mob2:mt-10 xs:mt-10 ml-auto x904:mr-[2%] xs:mr-[5%] x904:w-[108px]  sms:w-[174px] sm:w-[108px] mob2:w-[108px]  xs:w-[108px]  " alt="logo" />
            </div>
            <div className='mx-auto max-w-[1440px] w-full z-[30] flex items-center justify-between absolute left-1/2 transform -translate-x-1/2'>
                <img src={foreSmoke} ref={canforegroundOpacity2} style={{ opacity: 0 }} className={`mx-auto z-30 min-[904px]:w-[900px] tablet:w-[900px] md:w-[800px] md2:w-[800px] sms:w-[1160px] sm:w-[766px] mob2:w-[550px] xs:w-[450px] min-[1300px]:top-[594px] min-[1100px]:top-[476px] x904:top-[496px] md2:top-[782px]  sms:top-[766px] sm:top-[638px] mob2:top-[485px] xs:top-[433px] relative`} alt="can gradient" />
            </div>

            <>
                                {isAndroid && isChrome ? (
                                    <>
                                    {/* Render elements for Chrome on Android */}                 
                                    <div id='smoke4' style={{ opacity:0, backgroundImage:`url(${s1})`, backgroundSize:'cover', backgroundPosition: 'center' }}  className=" smokeAnimation animation1 z-[18] absolute inset-0   xs:left-[0px]  "></div>
                                    <div id='smoke5'   style={{ opacity:0, backgroundImage:`url(${s2})`, backgroundSize:'cover', backgroundPosition: 'center'  }}  className="smokeAnimation animation2 z-[19] absolute inset-0  xs:left-[0px]"></div>
                                    <div id='smoke6'   style={{ opacity:0, backgroundImage:`url(${s1})`, backgroundSize:'cover', backgroundPosition: 'center'  }} className=" smokeAnimation animation3 z-[18] absolute inset-0   xs:left-[0px] "></div>
                                    </>
                                ) : (
                                    <>
                                    {/* Render elements for other devices/browsers */}
                                    <div id='smoke4' ref={smok4} style={{ opacity:0, backgroundImage:`url(${s1})`, backgroundSize:'cover', backgroundPosition: 'center' }}  className=" z-[18] absolute inset-0   xs:left-[0px]  "></div>
                                    <div id='smoke5' ref={smok5}   style={{ opacity:0, backgroundImage:`url(${s2})`, backgroundSize:'cover', backgroundPosition: 'center'  }}  className=" z-[19] absolute inset-0  xs:left-[0px]"></div>
                                    <div id='smoke6' ref={smok6}  style={{ opacity:0, backgroundImage:`url(${s1})`, backgroundSize:'cover', backgroundPosition: 'center'  }} className="  z-[18] absolute inset-0  xs:left-[0px] "></div>
                                    </>
                                )}
                            </>
                            

            <div  ref={flavourRef} className='  top-0  min-[1300px]:h-[298px] x904:h-[60px]  mx-auto max-w-[1440px]  x904:absolute xs:relative w-full z-[29] left-1/2 translate-x-[-50%] flex items-center justify-between'>
                {/* FLAVOUR LEMON COPY DESKTOP ---------------------*/}
                <div className={`relative ml-auto  min-[1300px]:top-[347px] min-[1100px]:top-[375px] x904:top-[400px] desk2:w-[366px] min-[1300px]:w-[316px] min-[1200px]:w-[316px] lgm:w-[270px] x990:w-[250px] x920:w-[230px] x904:w-[210px] sms:w-[600px] sm:w-[550px] mob2:w-[410px] xs:w-[350px]  x904:block xs:hidden `}>
                    <div className='relative'> 
                        <img src={LemonJp} className="  ml-auto  z-20   desk2:w-[178px] x1220:w-[152.2px] lgm:w-[129.1px] x990:w-[126.91px] x920:w-[119.22px] x904:w-[110.45px] " alt="lemon" />
                    </div>
                    <div    className=" text-white  ml-auto text-right  relative min-[1300px]:pt-[30px]  min-[1300px]:pb-[35px]   min-[1100px]:pt-[22px] min-[1100px]:pb-[35px]  x990:pt-[18px] x990:pb-[35px] x920:pt-[16px] x904:pt-[14px] x904:pb-[25px]   tracking-[-0.6px] desk2:text-[22px] min-[1300px]:text-[19px]  x1220:text-[19px] lgm:text-[16px] x990:text-[16px] x920:text-[15px]  x904:text-[14px] sms:text-[20px] sm:text-[18px] mob2:text-[18px]  xs:text-[12.9px]   x920:leading-[1.3rem]   x904:leading-[1.2rem]  font-barlowBold font-extrabold whitespace-pre-wrap">
                        <p >
                        <span className='text-[#fedd00]  desk2:text-[64px]  leading-[0px] min-[1300px]:text-[55px]  x1220:text-[55px] lgm:text-[47px] x990:text-[46px] x920:text-[43px]  x904:text-[40px]'>
                            LEMON
                        </span>
                        <br/><br/>
                        THE CLASSIC, THE ORIGINAL, FEATURING<br/>SHOCHU, VODKA AND SODA MIX WITH<br/>A LEMON FLAVOR EXPLOSION.
                        <br/><br/>
                        ABV 6%
                        <br/><br/>
                        <span className='leading-[1.8rem]'>AVAILABLE IN:
                        <br/>
                        330ml</span>
                        
                        </p>
                    </div>
                    <img src={LemonIcons} className="  ml-auto  z-20   desk2:w-[183px] x1220:w-[184px]  lgm:w-[159.54px] x990:w-[140.76px] x920:w-[140.76px] x904:w-[140.76px]  sms:w-[177px] sm:w-[108px] mob2:w-[108px]  xs:w-[108px]  " alt="lemon" />
                    
                </div>
                 {/* FLAVOUR LEMON COPY END ---------------------*/}
                {/* CANS ----------------------------------------*/}
                <div className={`relative  xs:mx-auto w-[600px] min-[1300px]:w-[600px]  min-[1300px]:top-[0px] min-[1100px]:w-[500px] min-[1100px]:top-[100px]  x904:top-[174px]  md2:top-[280px] sms:top-[320px] sm:top-[250px] mob2:top-[190px] xs:top-[180px] x904:w-[450px] md2:w-[688px] sms:w-[600px] sm:w-[500px] mob2:w-[325px] xs:w-[300px] animated-div2 `}>
                    <div className=" mx-auto md:mt-15 xs:mt-0 xs:mx-auto  min-[1300px]:max-w-[600px] min-[1100px]:max-w-[500px] x904:max-w-[450px] sms:max-w-[600px] relative">
                        {/* GRAPEFRUIT */}     
                        <div id='grapefruit'  ref={grapefruitCan2} style={{ top:'45vh' }}  className={`  min-[1300px]:left-[298px] min-[1100px]:left-[251px]  x904:left-[221px] md2:left-[290px] sms:left-[290px] sm:left-[245px]  mob2:left-[154px] xs:left-[145px]    absolute min-[1300px]:w-[315px]  min-[1100px]:w-[260px]  x904:w-[229px]  min-[1100px]:mt-[51px] min-[1300px]:mt-[60px] x904:mt-[48px] md2:w-[325px]  sms:w-[281px] sms:mt-[58px] sm:w-[247px] sm:mt-[51px]  mob2:w-[195px] mob2:mt-[39px] xs:w-[157px] xs:mt-[47px]  `}>
                            <img src={can_grapefruit} className={`absolute z-20   `} alt="grapefruit can" />
                            <img ref={grapefruitfrostCan2} src={grapefruitFROST}  className={` absolute z-20 `} style={{ opacity:0 }} alt="frost grapefruit can" />
                        </div> 
                        {/* LEMON */}
                        <div id='lemon' ref={lemonCan2}  style={{ top:'45vh' }} className={`absolute min-[1300px]:w-[345px] min-[1300px]:left-[0px] min-[1100px]:w-[290px] min-[1100px]:left-[0px] x904:w-[255px] x904:left-[0px]  md2:w-[363px] sms:w-[310px]  md2:left-[-23px] sms:left-[22px] sm:w-[275px] sm:left-[7px] mob2:w-[217px] mob2:left-[-34px] xs:w-[184px] xs:left-[-15px] `}>
                            <img src={can_lemon} className={` absolute z-20`} alt="lemon can" />
                            <img  ref={lemonfrostCan2} src={lemonFROST} className={` absolute z-20 `} style={{ opacity:0 }} alt="frost lemon can" />
                        </div>
                    </div>
                </div>
                {/* CANS END ----------------------------------------*/}
                {/* FLAVOUR GRAPEFRUIT COPY DESKTOP---------------------- */}
                <div className={`relative mr-auto min-[1300px]:top-[347px] min-[1100px]:top-[375px] x904:top-[400px] desk2:w-[366px] min-[1300px]:w-[316px] min-[1200px]:w-[316px] lgm:w-[270px] x990:w-[250px] x920:w-[230px] x904:w-[210px]  sms:w-[600px] sm:w-[550px] mob2:w-[410px] xs:w-[350px]  x904:block xs:hidden`}>
                    <div className='relative'> 
                        <img src={GrapefruitJp} className="  mr-auto  z-20   desk2:w-[325px]  x1220:w-[277.9px] lgm:w-[235.7px] x990:w-[231.8px] x920:w-[217.8px] x904:w-[201.7px] " alt="grapefruit" />
                    </div>
                    <div    className=" text-white  ml-auto text-left  relative min-[1300px]:pt-[30px] min-[1300px]:pb-[15px]  min-[1100px]:pt-[22px]   min-[1100px]:pb-[15px]  x990:pt-[18px]  x990:pb-[15px] x920:pt-[16px] x904:pt-[14px]  x904:pb-[5px]  tracking-[-0.6px] desk2:text-[22px] min-[1300px]:text-[19px]  x1220:text-[19px] lgm:text-[16px]  x990:text-[16px]  x920:text-[15px]  x904:text-[14px]  x920:leading-[1.3rem]   x904:leading-[1.2rem]  font-barlowBold font-extrabold whitespace-pre-wrap">
                        <p > 
                        <span className='text-[#f15b5d]  desk2:text-[64px]  leading-[0px] min-[1300px]:text-[55px]  x1220:text-[55px] lgm:text-[47px] x990:text-[46px] x920:text-[43px]  x904:text-[40px]'>
                        GRAPEFRUIT
                        </span>
                        <br/><br/>
                        THE ULTIMATE SUMMER FLAVOUR<br/>FEATURING SHOCHU, VODKA AND SODA<br/>MIX WITH A GRAPEFRUIT FLAVOUR<br/>EXPLOSION.
                        <br/><br/>
                        ABV 6%
                        <br/><br/>
                        <span className='leading-[1.8rem]'>AVAILABLE IN:
                        <br/>
                        330ml</span>
                        
                        </p>
                    </div>
                    <img src={GrapefruitIcons} className="  mr-auto  z-20   desk2:w-[195px] x1220:w-[200px] lgm:w-[170px] x990:w-[150px] x920:w-[150px] x904:w-[150px]  sms:w-[177px] sm:w-[108px] mob2:w-[108px]  xs:w-[108px]  " alt="grapefruit" />
                    
                   
                </div>
                 {/* FLAVOUR GRAPEFRUIT COPY END ---------------------*/}
            </div>


            <div  className=' md:top-[1005px]  md2:top-[1005px]  sms:top-[990px] sm:top-[800px]  mob2:top-[600px]  xs:top-[538px]   top-0 x904:h-[298px] mx-auto max-w-[1440px]  relative w-full z-[50] left-1/2 translate-x-[-50%] xs:flex items-center justify-between x904:hidden'>
                {/* FLAVOUR LEMON COPY Mobile---------------------*/}
                <div className={`relative mx-auto  min-[1300px]:top-[347px] min-[1100px]:top-[375px] x904:top-[400px] md2:w-[304px]  sms:w-[250px] sm:w-[202px] mob2:w-[170px] xs:w-[126px]  `}>
                    <div className='relative'> 
                        <img src={LemonJpMob} className=" svg-image mr-auto  z-20   xl:w-[177px] x1220:w-[159px] lgm:w-[149px] x990:w-[139px] x920:w-[129px] x904:w-[119px]  md2:w-[182px] sms:w-[149.05px] sm:w-[108.57px] mob2:w-[91.27px]  xs:w-[74px]  " alt="lemon" />
                    </div>
                    <div    className=" text-[#1d1d1a]  ml-auto text-left  relative min-[1300px]:pt-[30px]  min-[1100px]:pt-[22px] x990:pt-[18px] x920:pt-[16px] x904:pt-[14px] md2:pt-[30px] sms:pt-[25px]  sm:pt-[20px] mob2:pt-[15px] mob2:pb-[7px] xs:pb-[6px]  xs:pt-[10px]   min-[1300px]:text-[22px] x990:text-[18px] x920:text-[16px] x904:text-[14px] md2:text-[31px] sms:text-[24px] sm:text-[19px] mob2:text-[16px]  xs:text-[12.9px]   x904:leading-[1.3rem] md2:leading-[1.8rem] sms:leading-[1.4rem] sm:leading-[1.2rem] mob2:leading-[1.2rem] xs:leading-[1rem] font-barlowBold font-extrabold whitespace-pre-wrap">
                        <p >
                        <span className='text-[#fedd00] leading-[0px] md2:text-[66px] sms:text-[54px] sm:text-[39px] mob2:text-[33px]  xs:text-[26.9px] '>
                            LEMON
                        </span>
                        <br/><br/>
                        
                        THE CLASSIC, THE ORIGINAL, FEATURING SHOCHU, VODKA AND SODA MIX WITH A<br/>LEMON FLAVOR EXPLOSION.
                        <br/><br/>
                        ABV 6%
                        <br/><br/>
                        <span className='sms:leading-[2.6rem] sm:leading-[2.3rem]'>AVAILABLE IN:
                        <br/>
                        330ml</span>
                        <br/><br/>
                        </p>
                    </div>
                    <img src={LemonIcons} className="  svg-image mr-auto  z-20  md2:w-[296px] sms:w-[244px] sm:w-[187px] mob2:w-[137px]  xs:w-[111px]  " alt="lemon" />
                    
                </div>
                 {/* FLAVOUR LEMON COPY END ---------------------*/}
               
                {/* FLAVOUR GRAPEFRUIT COPY Mobile---------------------- */}
                <div className={`relative mr-auto min-[1300px]:top-[347px] min-[1100px]:top-[375px] x904:top-[400px]  md2:w-[346px] sms:w-[294px] sm:w-[236px] mob2:w-[181px] xs:w-[145px]  `}>
                    <div className='relative'> 
                        <img src={GrapefruitJpMob} className=" svg-image mr-auto  z-20   xl:w-[326px]  x1220:w-[293px] lgm:w-[283px] x990:w-[273px] x920:w-[263px] x904:w-[253px]   md2:w-[337px] sms:w-[276px] sm:w-[201px] mob2:w-[169px]  xs:w-[137px]  " alt="grapefruit" />
                    </div>
                    <div    className=" text-[#1d1d1a]  ml-auto text-left  relative min-[1300px]:pt-[35px]  min-[1100px]:pt-[22px]  x990:pt-[18px] x920:pt-[16px] x904:pt-[14px] md2:pt-[30px]  sms:pt-[25px] sm:pt-[20px] mob2:pt-[15px]  xs:pt-[10px]  min-[1300px]:text-[22px]  x990:text-[18px] x920:text-[16px] x904:text-[14px] md2:text-[31px] sms:text-[24px] sm:text-[19px] mob2:text-[16px]  xs:text-[12.9px]   x904:leading-[1.3rem] md2:leading-[1.8rem] sms:leading-[1.4rem] sm:leading-[1.2rem] mob2:leading-[1.2rem] xs:leading-[1rem] font-barlowBold font-extrabold whitespace-pre-wrap">
                        <p >
                        <span className='text-[#f15b5d]  leading-[0px] md2:text-[66px] sms:text-[54px] sm:text-[39px] mob2:text-[33px]  xs:text-[26.9px]'>
                        GRAPEFRUIT
                        </span>
                        <br/> <br/>
                        THE ULTIMATE SUMMER FLAVOUR FEATURING SHOCHU, VODKA AND SODA MIX WITH A GRAPEFRUIT FLAVOUR EXPLOSION.
                        <br/><br/>
                        ABV 6%
                        <br/><br/>
                        <span className='sms:leading-[2.8rem] sm:leading-[2.5rem]'>AVAILABLE IN:
                        <br/>
                        330ml</span>
                        <br/><br/><br/>
                        </p>
                    </div>
                    <img src={GrapefruitIcons} className=" svg-image first-line: mr-auto  z-20 md2:w-[313.1px] sms:w-[261px]  sm:w-[201px] mob2:w-[160px]  xs:w-[130px]  " alt="grapefruit" />       
                </div>
                 {/* FLAVOUR GRAPEFRUIT COPY END ---------------------*/}
            </div>



        </div>
        {/* FLAVOUR SECTION END */}

        {/* WHERE TO BUY SECTION------------------------------------------------------------------- */}
        <div id="where-to-buy"  style={{ display: isContentVisible ? 'block' : 'none', position: isContentVisible ? 'relative' : 'fixed' }}  className='   mx-auto min-[1440px]:w-[1440px] xl:w-[1340px] min-[1220px]:w-[1200px] min-[1130px]:w-[1100px] min-[1050px]:w-[1000px] min-[920px]:w-[900px] min-[870px]:w-[850px] min-[820px]:w-[800px]  sms:w-[600px] sm:w-[400px]  mob2:w-[350px] xs:w-[250px]  '>
            <p   className="relative min-[1100px]:mt-[80px] md:mt-[80px] sms:mt-[60px] xs:mt-[40px] font-barlowBold font-extrabold x904:text-[32px] sms:text-[52px] sm:text-[42px] mob2:text-[32px]  xs:text-[28px] leading-tight text-[#0000000]">
                WHERE TO BUY
            </p>
            {/* <div  className=" x904:my-[86px] sms:my-[60px] xs:my-[20px] flex  x904:flex-row  xs:flex-col justify-between  min-[1220px]:w-[1051px] x904:w-[840px] sms:w-[550px] sm:w-[400px]  mob2:w-[350px]  xs:w-[250px] mx-auto "> */}
                {/* <a href="https://google.com" target="_blank" rel="noopener noreferrer">
                    <img src={coop} className="x902:mx-0 xs:mx-auto relative min-[1220px]:w-[105px] x904:w-[84px] sms:w-[150px] sm:w-[150px]  mob2:w-[105px]   xs:w-[99px]" alt="coop" /> 
                </a>
                <a href="https://google.com" target="_blank" rel="noopener noreferrer">
                    <img src={whsmith} className=" x902:mx-0 xs:mx-auto relative  min-[1220px]:w-[368px]  x904:w-[294px] sms:w-[532px] sm:w-[430px]  mob2:w-[301px] xs:w-[286px]" alt="wh smith" />
                </a>
                <a href="https://google.com" target="_blank" rel="noopener noreferrer">
                    <img src={yd} className=" x902:mx-0 xs:mx-auto relative min-[1220px]:w-[174px] x904:w-[139px]  sms:w-[250px] sm:w-[214px]  mob2:w-[150px] xs:w-[143px]" alt="yd" />
                </a>
                <a href="https://google.com" target="_blank" rel="noopener noreferrer">
                    <img src={nisa} className=" x902:mx-0 xs:mx-auto  relative min-[1220px]:w-[172px] x904:w-[138px]  sms:w-[250px] sm:w-[214px]  mob2:w-[150px] xs:w-[143px]" alt="nisa" />
                </a>
                <a href="https://google.com" target="_blank" rel="noopener noreferrer">
                    <img src={amazon} className=" x904:ml-8 x904:mr-0 relative  min-[1220px]:w-[368px]  x904:w-[294px] sms:w-[532px] sm:w-[430px]  mob2:w-[301px] xs:w-[286px]" alt="wh smith" />
                </a> */}
            {/* </div> */}
            <div  className=" x904:my-[86px] sms:my-[60px]  mob2:mt-[40px] xs:mt-[30px] xs:mb-[20px] flex  x904:flex-row  xs:flex-col justify-between  min-[1220px]:w-[1051px] x904:w-[840px] sms:w-[550px] sm:w-[400px]  mob2:w-[350px]  xs:w-[250px] mx-auto ">
                <a href="https://www.coop.co.uk/" target="_blank" rel="noopener noreferrer">
                    <img src={coop} className="x902:mx-0 xs:mx-auto relative min-[1220px]:w-[57.425px] x904:w-[41.02px] sms:w-[114.85px] sm:w-[98.45px]  mob2:w-[82.04px]  xs:w-[65.64px]" alt="coop" /> 
                </a>
                <a href="https://www.whsmith.co.uk/" target="_blank" rel="noopener noreferrer">
                    <img src={whsmith} className=" m x902:mx-0 xs:mx-auto relative  min-[1220px]:w-[201.25px]  x904:w-[143.75px] x904:my-0 sms:mt-6 sm:mt-5 sm:mb-0 mob2:my-1 xs:mt-4 sms:w-[402.5px] sm:w-[345px]  mob2:w-[287.5px] xs:w-[230px]" alt="wh smith" />
                </a>
                <a href="https://yosushi.com/" target="_blank" rel="noopener noreferrer">
                    <img src={yd} className=" x902:mx-0 xs:mx-auto relative min-[1220px]:w-[95.16px] x904:w-[67.98px] x904:my-0 mob2:my-1  xs:mt-3 sms:w-[190.32px] sm:w-[163.15px]  mob2:w-[135.96px] xs:w-[108.76px]" alt="yd" />
                </a>
                <a href="https://www.nisalocally.co.uk/" target="_blank" rel="noopener noreferrer">
                    <img src={nisa} className=" x902:mx-0 xs:mx-auto  relative min-[1220px]:w-[94.07px] x904:w-[67.2px] x904:my-0  mob2:my-1 xs:my-3 sms:w-[188.15px] sm:w-[161.28px]  mob2:w-[134.4px] xs:w-[107.5px]" alt="nisa" />
                </a>
                <a href="https://www.amazon.co.uk/196-Lemon-Shochu-Vodka-12x330ml/dp/B0D49S5WQ5?th=1" target="_blank" rel="noopener noreferrer">
                    <img src={amazon} className="  x902:mx-0 xs:mx-auto  relative  min-[1220px]:w-[171.74px]  x904:w-[122.66px] x904:my-0 sms:mb-3 sm:mb-4 mob2:mb-5  xs:mb-5 sms:w-[343.45px] sm:w-[294.4px]  mob2:w-[245.35px] xs:w-[196.25px] min-[1220px]:top-[6.5px] x904:top-[4px]" alt="amazon" />
                </a>
                <a href="https://www.tesco.com/groceries/en-GB/products/317654242" target="_blank" rel="noopener noreferrer">
                    <img src={tesco} className="   xs:mx-auto   x904:mr-0 relative  min-[1220px]:w-[171.74px]  x904:w-[122.66px] x904:my-0  sms:mt-4 sms:mb-3 sm:mt-4 sm:mb-10 mob2:mb-10  xs:mb-4 sms:w-[343.45px] sm:w-[294.4px]  mob2:w-[245.35px] xs:w-[196.25px] min-[1220px]:top-[6.5px] x904:top-[4px]" alt="tesco" />
                </a>
            </div>
            {/* <button 
                className="relative min-[1100px]:mt-[90px] md:mt-[80px] sms:mt-[60px] xs:mt-[40px]  min-[1100px]:mb-[120px] md:mb-[80px] sms:mb-[60px] xs:mb-[40px] font-barlowBold font-extrabold x904:text-[22px] sms:text-[34px] sm:text-[32px] mob2:text-[28px] xs:text-[24px] leading-tight text-[#fedd00] bg-[#1d1d1b] rounded-[15px] x904:px-10 x904:py-3 sms:px-14 sms:py-4  sm:px-10 sm:py-3  mob2:px-8 mob2:py-2 xs:px-6 xs:py-2">
                FIND STOCKLIST
            </button> */}
        </div>
        {/* WHERE TO BUY SECTION END------------------------------------------------------------------- */}

         {/* FOOTER SECTION------------------------------------------------------------------- */}
         <div style={{ display: isContentVisible ? 'block' : 'none', position: isContentVisible ? 'relative' : 'fixed' }}  className='  mx-auto w-full bg-[#1d1d1b] md:py-[50px] sms:py-[50px] xs:py-[40px]   '>
         <p className="relative  font-barlowBold font-extrabold x904:text-[21px] sms:text-[21px] sm:text-[20px] mob2:text-[19px]  xs:text-[18px] leading-tight text-[#fedd00]">
            © 2024 SUNTORY -196 
            </p>
            <br/>
            <p className="relative md:mt-[0px] sms:mt-[0px] xs:mt-[0px] font-barlowBold font-light x904:text-[16px] sms:text-[16px] sm:text-[15px] mob2:text-[14px]  xs:text-[13px] leading-tight text-[#ffffff] xs:mx-auto min-[1220px]:w-[467px] x904:w-[467px] sms:w-[467px] sm:w-[467px] mob2:w-[384px] xs:w-[300px]">
            {/* -196 Vodka Soda. Made with Shochu, Vodka and Soda, 6% Alc./Vol.<br/> */}
            Made in EU. Ingredients of EU and Non-EU origin. Produced under the authority of Suntory Spirits Ltd. Distributed by Lucozade Ribena Suntory Ltd. -196 Vodka Soda. Made with Shochu, Vodka and Soda, 6% Alc./Vol.<br/>
            </p>
            <div className="flex items-center x902:mx-0 xs:mx-auto min-[1220px]:w-[192px] x904:w-[192px] sms:w-[192px] sm:w-[192px] mob2:w-[192px] xs:w-[192px] ">
                <span className="relative pt-[6px] font-barlowBold font-normal x904:text-[20px] sms:text-[20px] sm:text-[20px] mob2:text-[20px] xs:text-[20px] leading-none text-[#ffffff]">
                    <b>18+</b>&nbsp;
                </span>
                <img src={drinkwareLogo} className="mt-2 relative min-[1220px]:w-[164px] x904:w-[164px] sms:w-[164px] sm:w-[164px] mob2:w-[164px] xs:w-[160px]" alt="drinkware-Logo" />
                
            </div>
            <div className="flex justify-center space-x-5 mt-10 h-[42px] xs:mx-auto w-[184px]">
                <a href="https://www.facebook.com/minus196uk?utm_source=website&utm_medium=social-icons&utm_campaign=minus196" target="_blank" rel="noopener noreferrer">
                    <img src={fb} className="relative h-full" alt="facebook" />
                </a>
                <a href="https://www.instagram.com/minus196uk/?utm_source=website&utm_medium=social-icons&utm_campaign=minus196" target="_blank" rel="noopener noreferrer">
                    <img src={insta} className="relative h-full" alt="instagram" />
                </a>
                <a href="https://www.youtube.com/@minus196uk?utm_source=website&utm_medium=social-icons&utm_campaign=minus196" target="_blank" rel="noopener noreferrer">
                    <img src={ytube} className="relative h-full" alt="youtube" />
                </a>
            </div>
        </div>
        {/* FOOTER SECTION END------------------------------------------------------------------- */}
   
      
        {/* /////////////////////////////////HOME PAGE CONTENT END/////////////////////////////////*/} 
        </div>
    );
}

export default Home;